<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 110px; margin-right: 5px;"></Input>
      {{showLang('com.group')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.lightGroupId" style="width: 110px;margin-right: 5px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <Option v-for="(s, idx) in areas" :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
      </Select>
      {{showLang('com.export.cn.connid')}}：
      <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')" style="width: 110px; margin-right: 5px;"></Input>
      应用类型:
      <Select :placeholder="showLang('save.select')" v-model="filter.useType" style="width: 110px;margin-right: 5px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <Option v-for="el,index in useTypes" :value="el.id" :key="'useTypes'+index">{{el.name}}</Option>
      </Select>
      设备类型:
      <Select :placeholder="showLang('save.select')" v-model="filter.smallType" style="width: 110px;margin-right: 15px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <Option v-for="el,index in devicesTypes" :value="el.code" :key="'devicesTypes'+index">{{el.name}}</Option>
      </Select>

      <Button size="default" type="primary" @click="getList(filter.index=1)" style="margin-right: 5px;">{{showLang('com.op.query')}}</Button>

      <!-- <Button type="success" :loading="exportLoading" @click="exportData">{{showLang('com.op.export')}}</Button> -->
    </div>
    <!-- <div class="query-data-area">
      <Button size="default" type="primary" @click="importLampNew" style="margin: 0 5px;">{{showLang('import.batch')}}</Button>
      <Button v-for="(btn, idx) in btns" size="default" :key="idx" type="primary" @click="docmd(btn)" style="margin: 2px 5px;">{{showLang(btn.lang)}}</Button>
      <Dropdown>
        <Button type="primary">
          {{showLang('com.data.re.energy.count')}}
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <template #list>
          <Form :model="formRepair" :label-width="120" style="margin-right: 30px; margin-top: 30px;">
            <FormItem label="修复起始日期">
              <DatePicker type="date" :placeholder="showLang('filter.time.start')" v-model="formRepair.start">
              </DatePicker>
            </FormItem>
            <FormItem label="修复截止日期">
              <DatePicker type="date" :placeholder="showLang('filter.time.end')" v-model="formRepair.end"></DatePicker>
            </FormItem>
            <FormItem :label-width="0">
              <Button v-if="funCodes('la')" size="default" type="primary" @click="repairStationEnergy" style="margin-left: 100px">{{showLang('com.data.re.energy.count')}}</Button>
            </FormItem>
          </Form>
        </template>
      </Dropdown>
      <Button v-if="funCodes('ld')" size="default" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</Button>
    </div> -->
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" :height="tabHeight" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}" @checkbox-all="selectAllEvent" :seq-config="{startIndex: (filter.index-1)*filter.size}" row-id="id">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="150" fixed="left" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="groupName" title="所在分区" width="150" header-align="center">
          <template #default="{ row }">
            {{row.groupName}}
          </template>
        </vxe-column>
        <vxe-column field="poleName" title="所在灯杆" width="150" header-align="center">
          <template #default="{ row }">
            {{row.poleName}}
          </template>
        </vxe-column>
        <vxe-column field="lightGroupName" title="灯控分组" width="150" header-align="center">
          <template #default="{ row }">
            {{row.lightGroupName}}
          </template>
        </vxe-column>
        <vxe-column field="code" title="通信ID" width="150" header-align="center">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column field="smallName" title="设备类型" width="150" header-align="center">
          <template #default="{ row }">
            {{row.smallName}}
          </template>
        </vxe-column>
        <vxe-column field="useTypeName" title="应用类型" width="150" header-align="center">
          <template #default="{ row }">
            {{row.useTypeName}}
          </template>
        </vxe-column>
        <vxe-column field="channel" title="上行通道号" width="150" header-align="center">
          <template #default="{ row }">
            {{row.channel}}
          </template>
        </vxe-column>

        <vxe-column field="buildDate" title="安装日期" width="150" header-align="center">
          <template #default="{ row }">
            {{row.buildDate}}
          </template>
        </vxe-column>

        <vxe-column width="160" fixed="right">
          <template v-slot:header>
            <Button type="primary" class="mr5" @click="handleAdd">{{showLang('com.op.add')}}</Button>
            <Button type="primary" class="mr5" @click="getList">{{showLang('com.op.refresh')}} </Button>
          </template>
          <template #default="{ row }">
            <Button type="primary" class="mr5" @click="handleEdit(row)">{{showLang('com.op.edit')}}</Button>
            <Button type="error" @click="handleDelete(row)">{{showLang('com.op.del')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <p>
        <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange">
        </vxe-pager>
      </p>
      <ModalLampEdit v-model="showEditModal" :item="editItem" :areas="areas" @saved="itemSaved" />
      <!-- <ModalLampImportNew v-model="showImportModalNew" :item="editItem" :areas="areas"  @saved="itemSaved" />
      <ModalEditArgs v-model="showArgModal" :item="editItem" :areas="areas" @saved="argsSaved" />
      <ModalExportChangeName v-model="showExportChangeNameModal" :item="editItem" @saved="getList" /> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLampEdit from './ModalLampEdit'
// import ModalLampImportNew from './ModalLampImportNew'
// import ModalEditArgs from './ModalEditArgs'
// import ModalExportChangeName from './ModalExportChangeName'
export default {
  name: 'ConfigLampIndex',
  components: {
    ModalLampEdit,
    // ModalLampImportNew,
    // ModalEditArgs,
    // ModalExportChangeName,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      showExportChangeNameModal: false,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      pageSizes: [20, 100, 500, 1000, 5000],
      showImportModalNew: false,
      showImportModalOld: false,
      exportLoading: false,
      dataRefresh: 0,
      filter: {
        "groupId": "", //所在分区ID
        "name": "", //灯控名称
        "code": "", //灯控通信ID
        "useType": 0, //应用类型
        "smallType": 0, //设备类型
        "lightGroupId": "", //灯控分组ID
        "index": 1, //当前页码
        "size": 20, //分页大小
      },
      total: 0,
      exportExcel: {
        data: [],
        isAll: false,
      },
      form: {
        name: '',
        areaId: 0,
      },
      areas: [],
      useTypes: [],
      devicesTypes: [],
      btns: [
        { code: 'exportChangeName', name: '导出修改名称', lang: 'com.ope.export.change.name' },
        // { code: 'updateLightOutput', name: '关联控制输出',lang:'com.ope.association.ch' },
        { code: 'updateControlChannel', name: '设定控制通道', lang: 'com.ins.set.updateControlChannel' },
        { code: 'updateLightGroup', name: '设定分组', lang: 'com.ins.set.updateLightGroup' },
        { code: 'updateLightType', name: '设定类型', lang: 'com.ins.set.updateLightType' },
        { code: 'updateLightTimeTable', name: '设定时间表', lang: 'com.ins.set.updateLightTimeTable' },
        { code: 'updateLeakageRate', name: '设定漏电阈值', lang: 'com.ins.set.updateLeakageRate' },
        // { code: 'updateWaterRate', name: '设定水浸阈值',lang:'com.ins.set.updateWaterRate' },
        { code: 'updateTempRate', name: '设定温度阈值', lang: 'com.ins.set.updateTempRate' },
        { code: 'updateVoltageRate', name: '设定电压阈值', lang: 'com.ins.set.updateVoltageRate' },
        { code: 'updateLightEnableAlarm', name: '设定电流阈值', lang: 'com.ins.set.updateCurrentRate' },
      ],
      formRepair: {
        start: '',
        end: '',
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'newLightTypes', 'deviceTypes', 'smallTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groupTreeSelectedNode']),

  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.filter.index = 1;
      this.getList();
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    let now = new Date();
    let monthFirst = now.monthFirstDay();
    monthFirst.setMonth(monthFirst.getMonth() - 1);
    this.formRepair.start = monthFirst.format('yyyy-MM-dd');
    let monthLast = monthFirst.monthLastDay();
    this.formRepair.end = monthLast.format('yyyy-MM-dd');
    this.getList();
    this.searchAjax();
  },
  destroyed: function () {
  },
  methods: {
    searchAjax() {
      this.$axios.post(`api/light/GetLightGroupList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'areas', res.data);
        }
      });
      this.$axios.post(`api/light/GetUseTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'useTypes', res.data);
        }
      });
      this.$axios.post(`api/light/GetDevicesTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devicesTypes', res.data);
        }
      });
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList(true)
    },
    selectAllEvent({ checked }) {//全选
      this.exportExcel.isAll = checked;
      if (!checked) {
        this.exportExcel.data = [];
      }
    },
    checkRowKeys() {//默认选中
      let table = this.$refs.chkTable;
      this.exportExcel.data.forEach(el => {
        this.list.forEach((ele) => {
          if (ele.id == el.id) {
            table.setCheckboxRow(table.getRowById(ele.id), true);
          }
        });
      })
      this.dataRefresh++;
    },
    exportData: function () {
      if (!this.exportExcel.isAll && this.exportExcel.data.length == 0) {
        this.$Message.warning(this.showLang('com.export.err.record'));
        return;
      }
      let ajaxData = {
        "groupId": '',
        "stationId": 0,
        "name": this.filter.name,
        "code": this.filter.code,
        "lampGroupId": this.filter.lampGroupId,
        "poleId": this.filter.poleId,
        "smallTypeId": this.filter.smallTypeId,
        "typeId": this.filter.typeId,
        "timeId": this.filter.timeId,
        "isAll": this.exportExcel.isAll,
        "ids": [],
      }
      if (this.selectedNode.type == this.productCodes.station) {
        ajaxData.stationId = this.selectedNode.id;
      } else {
        ajaxData.groupId = this.selectedNode.id;
      }
      this.exportExcel.data.forEach(el => {
        ajaxData.ids.push(el.id)
      })
      this.$axios.request({
        url: `//${this.domains.trans}/station/config/ExportLampsByFilter`,
        method: 'post',
        data: ajaxData,
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${this.showLang('export.light.file.name')}.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    docmd: function (params) {
      // console.log('do cmd', params)
      if (params.code == 'exportChangeName') {
        let groupId = 0, stationId = 0;
        if (this.selectedNode.type == this.productCodes.station) {
          stationId = this.selectedNode.id;
        } else {
          groupId = this.selectedNode.id;
        }
        this.filter.groupId = groupId;
        this.filter.stationId = stationId;
        this.editItem = this.filter;
        this.showExportChangeNameModal = true;
        return;
      } else if (params.code == 'updateLightOutput') {
        if (this.selectedNode.type != this.productCodes.station) {
          this.$Message.warning(this.showLang('com.save.err.station'));
          return;
        }
        params.stationId = this.selectedNode.id;
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      this.editItem = params;
      this.showArgModal = true;
    },
    argsSaved: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$axios.post(`//${this.domains.trans}/station/config/BatchEditLight`, {
        list, code: params.code, args: params.args
      }).then(res => {
        if (res.code == 0) {
          this.getList();
        }
      });
    },

    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex);
      let leng = this.exportExcel.data.filter((el) => el.id == this.list[rowIndex].id)
      if (leng.length == 0 && this.list[rowIndex].checked) {
        this.exportExcel.data.push(this.list[rowIndex])
      }
      if (!this.list[rowIndex].checked && leng.length > 0) {
        this.exportExcel.data = this.exportExcel.data.filter(item => item.id !== this.list[rowIndex].id)
      }
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 60;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`api/light/DeleteLightConfig`, { list: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.name),
        onOk: async () => {
          this.$axios.post(`api/light/DeleteLightConfig`, { id: params.id }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    repairStationEnergy: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.site.energy'),
        onOk: async () => {
          let start = new Date(this.formRepair.start).format('yyyy-MM-dd');
          let end = new Date(this.formRepair.end).format('yyyy-MM-dd');
          this.$axios.post(`//${this.domains.trans}/third/test/RepairStationBeforeEnergy`, { groupId, stationId, start, end }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.data.count.record', res.data));
            }
          });
        }
      });
    },
    getList: function () {
      this.filter.groupId = this.groupTreeSelectedNode.id == 0 ? '' : this.groupTreeSelectedNode.id;
      this.$axios.post(`api/light/GetLightConfigList`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
          setTimeout(() => {
            this.checkRowKeys();
          }, 100);
          this.dataRefresh++;
        }
      });
    },
    importLampNew: function () {
      this.item = {};
      this.showImportModalNew = true;
    },
    importLampOld: function () {
      this.$Modal.info({
        title: this.showLang('com.tips.function'),
        content: this.showLang('com.tips.function.con'),
      })
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  width: calc(100% - 300px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.query-data-area {
  align-items: center;
  margin: 5px 0;
  padding: 5px 0;
}
.alarm-data-area {
  width: calc(100%);
  height: 100px;
  flex: auto;
  padding-left: 10px;
}
</style>