<template>
  <!-- <img :src="img.visual.tunnel.desktop.tunnelWithin" alt="" class="device-total-icon"> -->
  <div class="box-container" ref="boxTreeHeight" :style="{backgroundImage:'url('+img.visual.tree.treeBg+')'}">
    <div class="box-slider">
      <MyTree class="box-tree" ref="tree" :data="treeData" />
      <List v-if="filter.length > 0" border size="small" class="tree-search-result">
        <template v-if="filterList.length == 0">
          {{isShowTip?showLang('com.data.no'):showLang('com.data.search')}}
        </template>
        <template v-else v-for="(item, idx) in filterList">
          <ListItem class="node-selected" @click.native="selectIt(item)" :key="idx">{{item.name}}</ListItem>
        </template>
      </List>
    </div>
    <!-- <div class="tree-search">
      <Input search clearable v-model="filter" size="large" autocomplete="off" class="text-bg" suffix="ios-search" placeholder="请输入搜索名称" />
    </div> -->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import MyTree from './MyTree'
export default {
  name: 'CommonTreeIndex',
  components: {
    MyTree,
  },
  props: {
    allSimple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showEditGroup: false,
      showChangeGroup: false,
      isShowTip: false,
      editItem: {},
      filter: '',
      result: [],
      filterList: [],
      deviceAll: [],//设备
      timeEnter: null,
      treeData: [],
      treeArr: [],
    }
  },
  watch: {
    filter() {
      this.keyEnter();
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['productCodes', 'deviceTypes']),
    ...mapState('group', ['groupTreeSelectedNode']),
    selectedPowers: function () {
      return this.selectedDevices;
    },
  },
  mounted: function () {
    this.getGroups();
  },
  destroyed: function () {
  },
  methods: {
    getGroups: async function () {
      let res = await this.$axios.post(`api/auth/GetMyGroups`, {});
      if (res.code !== 0) {
        return;
      }
      res.data.forEach(el => {
        el.expand = true;
        el.title = el.name;
        el.typeTitle = 'group';
      });
      this.treeData = this.buildTree(res.data)
      let res2 = [];
      if (this.allSimple) {
        res2 = await this.$axios.post(`api/powerbox/GetAllSimple`, {});
        res2.data.forEach(el => {
          el.expand = true;
          el.title = el.name;
          el.typeTitle = 'station';
          el.parentId = el.groupId;
        });
        this.treeData = [];
        this.treeData = this.buildTree([...res.data, ...res2.data])
      }

      this.$set(this, "treeArr", res.data);
      if (this.groupTreeSelectedNode.id == 0 && this.treeData.length > 0) {
        this.$store.commit('group/selectGroupTreeNode', this.treeData[0]);
      }

    },
    buildTree(items, parentId = '') {
      return items.filter(item => item.parentId === parentId).map(item => ({...item,children: this.buildTree(items, item.id),}));
    },
    keyEnter() {
      let this_ = this;
      if (this_.filter == []) {
        this_.$set(this_, 'filterList', []);
        return false;
      }
      if (this_.timeEnter) {
        clearTimeout(this_.timeEnter)
      }
      this_.isShowTip = false;
      this_.timeEnter = setTimeout(function () {
        this_.timeEnter = null;
        if (this_.filter == []) {
          return false;
        }
        this_.treeArr.forEach(el => {
          if (el.title.indexOf(this_.filter) > -1) {
            this_.filterList.push(el)
          }
        });
        this_.isShowTip = true;
        // this_.$axios.post(`//${this_.domains.trans}/station/config/FilterTree`, { 'filter': this_.filter }).then(res => {
        //   this_.$set(this_, 'filterList', res.data);
        //   this_.isShowTip=true;
        // });
      }, 1500)
    },
    selectIt: function (nodeData) {
      this.$store.commit('group/selectNodeByIdNew', { node: nodeData });
    },
  }
}
</script>
<style scoped>
.box-container {
  width: 100%;
  height: 100%;
  align-items: stretch;
  position: relative;
  overflow-y: auto;
  border-radius: 8px;
  background-size: 100% 100%;
}
::-webkit-scrollbar {
  display: none;
  /* overflow-y:none; */
}
element::-webkit-scrollbar {
  display: none;
}
/* 隐藏整个垂直滚动条 */
/* .element::-webkit-scrollbar {
    display: none;
} */

/* 或者只隐藏滚动条的Y轴 */
/* .element::-webkit-scrollbar {
    width: 0;
} */
.text-bg {
  background-color: #eef7ff !important;
}
.text-bg input {
  background-color: #eef7ff !important;
}
.box-slider {
  flex: none;
  width: 100%;
  /* background-color: #041B32; */
  /* background-color: #ffffff; */
  display: flex;
  flex-direction: column;
}
.box-tree {
  flex: auto;
}
.tree-search {
  position: fixed;
  bottom: 60px;
  left: 62px;
  height: 34px;
  width: 270px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tree-search img {
  /* outline-style: none; */
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.tree-search input {
  outline-style: none;
  width: 210px;
  height: 15px;
  /* font-size: 14px;
  background-color: #132D48;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4D6781; */
  background-color: transparent;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #889fb4;
}
.tree-search-result {
  position: fixed;
  bottom: 98px;
  left: 62px;
  width: 270px;
  background: white;
  max-height: 300px;
  overflow-y: auto;
}
.node-selected {
  cursor: pointer;
}
</style>
