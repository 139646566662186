<template>
  <Modal v-model="showModal" :mask-closable="false" width="1500px">
    <div slot="header">{{showLang('timetable.edit.lamp.chronometer')}}</div>
    <Form ref="form" :model="form" :label-width="70">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-bottom: 5px;width: 400px">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder=""></Input>
      </FormItem>
      <FormItem :label-width="0">
        <table class="set-table" v-if="form.list">
          <tr>
            <td style="height:60px">{{showLang('com.date.period')}}</td>
            <td>{{showLang('com.date.time')}}(min)</td>
            <td>{{showLang('com.lighting.op')}}(%)</td>
            <td>{{showLang('com.lighting.oc')}}(%)</td>
            <td>{{showLang('com.but.linkageLight')}}(%)</td>
          </tr>
          <template v-for="el,index in form.list">
            <tr :key="index">
              <td style="height:60px">{{showLang('com.date.period')}}{{index+1}}</td>
              <td><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="el.minute" placeholder=""></Input></td>
              <td><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="el.bright" placeholder=""></Input></td>
              <td><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="el.color" placeholder=""></Input></td>
              <td><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="el.union" placeholder=""></Input></td>
            </tr>
          </template>
        </table>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalLightTable',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      tabs: [],
      isAdd: false,
      index: -1,
      form: {
        id: 0,
        name: '',
        content: [],
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.index = this.item.index;
        this.isAdd = this.item.isAdd;
        if (!this.item.isAdd) {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.getTableContent();
        } else {
          this.form.id = '';
          this.form.name = '';
          this.form.list = [
            { "minute": 111, "bright": 80, "color": 80, "union": 60 },
            { "minute": 220, "bright": 10, "color": 80, "union": 20 },
            { "minute": 330, "bright": 0, "color": 80, "union": 0 },
            { "minute": 440, "bright": 0, "color": 80, "union": 0 },
            { "minute": 550, "bright": 0, "color": 80, "union": 0 },
            { "minute": 660, "bright": 0, "color": 80, "union": 0 }
          ]
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted: function () {
  },
  methods: {
    getTableContent: function () {
      this.loading = true;
      this.$axios.post(`api/light/GetPlan1Desc`, { id: this.form.id }).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this.form, 'list', res.data);
        }
      })
    },
    ok: async function () {
      this.form.name = this.form.name.trim();
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      for (let index = 0; index < this.form.list.length; index++) {
        let el = this.form.list[index].minute * 1;
        if (el < 0 || el > 1440) {
          this.$Message.warning(this.showLang('save.time.minute.between', index + 1, 0, 1440));
          return;
        }
      }
      this.loading = true;
      this.$axios.post(`api/light/SavePlan1`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.set-table tr td {
  border: solid 1px gray;
  text-align: center;
  white-space: nowrap;
}
.set-table {
  width: 100%;
}
.time-span {
  display: flex;
}
.min-width {
  width: 70px;
  flex: none;
  margin: 0;
}
.ch-width {
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span {
  width: 25px;
  flex: none;
}
.ch-width div {
  width: 40px;
  flex: auto;
}
</style>