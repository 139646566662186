<template>
  <Modal v-model="showModal" :mask-closable="false" width="1350px" :styles="{top: '10px'}">
    <div slot="header">{{showLang('com.edit.lamp.info')}}</div>
    <Form ref="form" :model="form" :label-width="130">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" placeholder="单灯名称" style="width: 200px; margin-right: 38px;"></Input>
          站点
          <Select :placeholder="showLang('save.select')" v-model="form.groupId" @on-change="stationChanged" :disabled="!isAdd" style="width: 200px; margin:0 10px;">
            <Option v-for="(s, idx) in groupData" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
          {{showLang('com.device.pole.association')}}
          <Select :placeholder="showLang('save.select')" v-model="form.poleId" style="width: 200px; margin: 0 10px;">
            <template v-for="(s, idx) in poles">
              <Option :value="s.id" :key="idx">{{s.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="connectType" label="应用类型" style="margin-right: 15px">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.useType" style="width: 200px;" class="mr10">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <template v-for="(s, idx) in useTypes">
              <Option :value="s.id" :key="idx">{{s.name}}</Option>
            </template>
          </Select>
          设备类型
          <Select :placeholder="showLang('save.select')" v-model="form.smallType" style="width: 200px; margin: 0 10px;">
            <template v-for="el in devicesTypes">
              <Option :value="el.code" :key="el.code">{{el.name}}</Option>
            </template>
          </Select>
          通信ID
          <Input type="text" v-model="form.code" style="width: 200px;  margin: 0 10px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="stationId" label="灯控分组">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.lightGroupId" style="width: 200px;" class="mr10">
            <template v-for="(s, idx) in areas">
              <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
            </template>
          </Select>
          安装日期
          <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" placeholder="请选择安装日期" :editable="false" style="width: 200px;margin:0 10px"></DatePicker>
        </div>
      </FormItem>
      <!-- <FormItem prop="connectType" :label="showLang('com.export.cn.association')" style="margin-right: 15px">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.deviceId" style="width: 425px; margin-right: 25px;" @on-change="getUseablePlan">
            <Option v-for="(dev, didx) in devices" :key="didx" :value="dev.id">{{getDeviceName(dev)}}</Option>
          </Select>
           {{showLang('com.ins.timeTable')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.timeId" style="width: 400px; margin: 0 10px;">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <template v-for="(s, idx) in timeTables">
              <Option :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem> -->
      <FormItem prop="env" :label="showLang('com.state.is.v.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.voltageAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.voltageLower" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          <Input type="number" v-model="form.voltageUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="env1" label="115B降功率电压" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.voltageUpperAdjust" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          是否联动：
          <i-switch v-model="uhbEnu" @on-change="uhbEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'uhb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input v-if="uhbEnu" type="number" v-model="form.voltageUpperBright" style="width: 200px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">0-100(%)</span></Input>
          <Input type="number" v-model="form.voltageLowerAdjust" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
          是否联动：
          <i-switch v-model="ulbEnu" @on-change="ulbEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'ulb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input v-if="ulbEnu" type="number" v-model="form.voltageLowerBright" style="width: 200px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">0-100(%)</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enc" :label="showLang('com.state.is.i.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.currentAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.currentLower" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">A</span></Input>
          <Input type="number" v-model="form.currentUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">A</span></Input>
        </div>
      </FormItem>
      <FormItem prop="ent" :label="showLang('com.state.is.t.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.tempAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.tempLevel1" style="width: 250px; margin: 0 5px;"><span slot="prepend">一级阈值</span><span slot="append">℃</span></Input>
          <Input type="number" v-model="form.tempLevel2" style="width: 250px; margin: 0 5px;"><span slot="prepend">二级阈值</span><span slot="append">℃</span></Input>
          是否联动：
          <i-switch v-model="toutEnu" @on-change="toutEnuChanged" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px" :key="'ulb'+uhbEnu">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-if="toutEnu" v-model="form.tempLevel2Bright" style="width: 220px; margin: 0 5px;"><span slot="prepend">调光值</span><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="leac" :label="showLang('com.state.is.leac.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.leakageCurrentAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.leakageCurrentUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.cl')}}</span><span slot="append">mA</span></Input>
        </div>
      </FormItem>
      <FormItem prop="leav" :label="showLang('com.state.is.leav.police')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.leakageVoltageAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.leakageVoltageUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.lu')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="engyro" :label="showLang('com.state.is.gyro.police')" style="margin-right: 15px">
        <i-switch v-model="form.gyroAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
          <span slot="open">{{showLang('com.state.enable')}}</span>
          <span slot="close">{{showLang('com.state.disable')}}</span>
        </i-switch>
      </FormItem>
      <template v-for="el,n in form.list">
        <FormItem :prop="`en${n}`" :label="`${n+1}路控制`" style="margin-right: 15px" :key="n">
          <div class="flex-row">
            <template>
              <Input type="number" v-model="el.after" style="width: 160px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span slot="append">W</span></Input>
              <Input type="number" v-model="el.powerLower" style="width:150px; margin: 0 5px;"><span slot="prepend">下限值</span><span slot="append">%</span></Input>
              <Input type="number" v-model="el.powerUpper" style="width: 150px; margin: 0 5px;"><span slot="prepend">上限值</span><span slot="append">%</span></Input>
              <Input type="number" v-model="el.before" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span slot="append">W</span></Input>
              <Input type="number" v-model="el.count" style="width: 140px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
              <Input type="number" v-model="el.adjust" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.pc')}}</span></Input>
              <Button type="primary" v-if="n==0" class="mr5" @click="addList()">增加一路</Button>
              <Button type="error" v-if="n!=0" class="mr5" @click="delList(n)">删除这路</Button>
            </template>
          </div>
        </FormItem>
        <FormItem :prop="`en${n}`" :label="`${n+1}路控制方式`" style="margin-right: 15px" :key="`en${n}`">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="el.mode" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in controlMode" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联计时日表：
            <Select :placeholder="showLang('save.select')" v-model="el.planId1" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in timeId1Data" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联准时日表：
            <Select :placeholder="showLang('save.select')" v-model="el.planId2" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in timeId2Data" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
          </div>
        </FormItem>
      </template>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    areas: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      accounts: [],
      poles: [],
      devices: [],
      timeTables: [],
      outputs: [],
      devicesTypes: [],
      timeId1Data: [],
      timeId2Data: [],
      groupList: [],
      groupData: [],
      useTypes: [],
      controlMode: [{ name: '计时日表', id: 0 }, { name: '准时日表', id: 1 }],
      uhbEnu: false,
      ulbEnu: false,
      toutEnu: false,
      form: {
        "id": "",
        "poleId": "", //所在灯杆
        "groupId": "", //所在分区
        "lightGroupId": "", //所在灯控分组ID
        "gatewayId": "", //灯控网关ID
        "name": null, //名称
        "useType": 101, //应用类型
        "smallType": 0, //设备类型
        "code": null, //通信ID
        "channel": 6, //上行通道号
        "buildDate": "-", //安装日期
        "voltageAlarm": false, //电压是否报警
        "voltageLower": 80, //电压下限值
        "voltageUpper": 265, //电压上限值
        "voltageUpperAdjust": 277, //115B：降功率电压上限（严重报警，联动调光），默认277V
        "voltageUpperBright": 255, //115B：降功率过压联动调光值（0-100，其它值功能无效，默认255）
        "voltageLowerAdjust": 100, //115B：降功率电压下限（严重报警，联动调光），默认100V
        "voltageLowerBright": 255, //115B：降功率欠压联动调光值（0-100，其它值功能无效，默认255）
        "currentAlarm": false, //电流是否报警
        "currentLower": 0.3, //电流下限值
        "currentUpper": 10, //电流上限值
        "leakageCurrentAlarm": false, //漏电电流是否报警
        "leakageCurrentUpper": 500, //漏电电流阈值
        "leakageVoltageAlarm": false, //漏电电压是否报警
        "leakageVoltageUpper": 100, //漏电电压阈值
        "tempAlarm": false, //温度是否报警
        "tempLevel1": 50, //一级温度阈值
        "tempLevel2": 80, //二级温度阈值
        "tempLevel2Bright": 0, //降功率调光值
        "gyroAlarm": false, //倾斜是否报警
        "list": [ //单灯通道列表
          {
            "channel": 1, //通道号
            "after": 100, //额定功率
            "before": 250, //改造前功率
            "count": 1, //光源数量
            "adjust": 1, //测量校准系数
            "planId1": "", //计时日表ID
            "planId2": "", //准时日表ID
            "mode": 0, //控制模式
            "powerLower": 50, //功率下限值
            "powerUpper": 250, //功率上限值
          }
        ]
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.GetLightConfigDesc();
          // setTimeout(() => {
          //   this.getUseablePlan();
          // }, 500);
        } else {
          this.form = {
            "id": "",
            "poleId": "", //所在灯杆
            "groupId": "", //所在分区
            "lightGroupId": "", //所在灯控分组ID
            "gatewayId": "", //灯控网关ID
            "name": null, //名称
            "useType": 101, //应用类型
            "smallType": 0, //设备类型
            "code": null, //通信ID
            "channel": 6, //上行通道号
            "buildDate": "-", //安装日期
            "voltageAlarm": false, //电压是否报警
            "voltageLower": 80, //电压下限值
            "voltageUpper": 265, //电压上限值
            "voltageUpperAdjust": 277, //115B：降功率电压上限（严重报警，联动调光），默认277V
            "voltageUpperBright": 255, //115B：降功率过压联动调光值（0-100，其它值功能无效，默认255）
            "voltageLowerAdjust": 100, //115B：降功率电压下限（严重报警，联动调光），默认100V
            "voltageLowerBright": 255, //115B：降功率欠压联动调光值（0-100，其它值功能无效，默认255）
            "currentAlarm": false, //电流是否报警
            "currentLower": 0.3, //电流下限值
            "currentUpper": 10, //电流上限值
            "leakageCurrentAlarm": false, //漏电电流是否报警
            "leakageCurrentUpper": 500, //漏电电流阈值
            "leakageVoltageAlarm": false, //漏电电压是否报警
            "leakageVoltageUpper": 100, //漏电电压阈值
            "tempAlarm": false, //温度是否报警
            "tempLevel1": 50, //一级温度阈值
            "tempLevel2": 80, //二级温度阈值
            "tempLevel2Bright": 0, //降功率调光值
            "gyroAlarm": false, //倾斜是否报警
            "list": [ //单灯通道列表
              {
                "channel": 1, //通道号
                "after": 100, //额定功率
                "before": 250, //改造前功率
                "count": 1, //光源数量
                "adjust": 1, //测量校准系数
                "planId1": "", //计时日表ID
                "planId2": "", //准时日表ID
                "mode": 0, //控制模式
                "powerLower": 50, //功率下限值
                "powerUpper": 250, //功率上限值
              }
            ]
          }
          this.form.groupId = this.groupTreeSelectedNode.id;
        }

        this.getLightList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.deviceId'() {
    //   this.getUseablePlan();
    // }
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  mounted: function () {
  },
  methods: {
    addList() {
      if (this.form.list.length == 4) {
        this.$Message.error('最多添加4路');
        return false
      }
      this.form.list.push({
        "channel": this.form.list.length + 1, //通道号
        "after": 100, //额定功率
        "before": 250, //改造前功率
        "count": 1, //光源数量
        "adjust": 1, //测量校准系数
        "planId1": "", //计时日表ID
        "planId2": "", //准时日表ID
        "mode": 0, //控制模式
        "powerLower": 50, //功率下限值
        "powerUpper": 250, //功率上限值
      })
    },
    delList(index) {
      this.form.list.splice(index, 1);
      this.form.list.forEach((el, index) => {
        el.channel = index
      });
    },
    toutEnuChanged() {
      if (this.toutEnu) {
        this.form.tempLevel2Bright = 0
      } else {
        this.form.tempLevel2Bright = 255
      }
    },
    ulbEnuChanged() {
      if (this.ulbEnu) {
        this.form.voltageLowerBright = 0
      } else {
        this.form.voltageLowerBright = 255
      }
    },
    uhbEnuChanged() {
      if (this.uhbEnu) {
        this.form.voltageLowerAdjust = 0
      } else {
        this.form.voltageLowerAdjust = 255
      }
    },
    GetLightConfigDesc(){
      this.$axios.post(`api/light/GetLightConfigDesc`, {id:this.item.data.id}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'form', res.data);
        }
      });
    },
    getLightList() {//计时日表 准时日表
      this.timeId1Data = [];
      this.timeId2Data = [];
      this.$axios.post(`api/light/GetPlan1List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeId1Data', res.data);
        }
      });
      this.$axios.post(`api/light/GetPlan2List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeId2Data', res.data);
        }
      });
      this.$axios.post(`api/light/GetUseTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'useTypes', res.data);
        }
      });
      this.$axios.post(`api/light/GetDevicesTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devicesTypes', res.data);
        }
      });
      this.$axios.post(`api/core/GetPoleList`, {
        "groupId": this.groupTreeSelectedNode.id, "poleId": "", "name": "", "index": 1, "size": 2000000,
      }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'poles', res.data.list);
        }
      });
      this.$axios.post(`api/auth/GetMyGroups`, {}).then(res => {
        if (res.code == 0) {
          this.groupList = this.buildTree(res.data)
          this.groupData = [];
          let arr = this.findByNameAndSubsets(this.groupList, this.groupTreeSelectedNode.id);
          this.checkboxIsAll(arr[0])
        }
      });
    },
    buildTree(items, parentId = '') {
      return items.filter(item => item.parentId === parentId).map(item => ({ ...item, children: this.buildTree(items, item.id), }));
    },
    findByNameAndSubsets(groups, id) {
      let result = [];
      function traverse(group) {
        if (group.id === id) {
          result.push({ ...group }); // 使用解构赋值来复制对象，避免直接修改原始数据
        }

        if (group.children && group.children.length > 0) {
          group.children.forEach(child => {
            traverse(child);
          });
        }
      }
      groups.forEach(group => {
        traverse(group);
      });

      return result;
    },
    checkboxIsAll(data) {
      this.groupData.push(data);
      if (data.children.length == 0) return false;
      data.children.forEach(childNode => {
        this.groupData.push(childNode);
        this.setCheckbox(childNode);
      });
    },
    setCheckbox(node) {
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => {
          this.setCheckbox(childNode);
          this.groupData.push(childNode);
        });
      }
    },
    // getUseablePlan: function () {
    //   this.timeId2Data = [];
    //   let devs = this.devices.filter(p => (p.smallType == 83 || p.smallType == 176) && p.id == this.form.deviceId);

    //   if (this.form.content.uhb != 255 && this.form.content.uhb !=undefined) {
    //     this.uhbEnu = true;
    //   } else {
    //     this.uhbEnu = false;
    //   }
    //   if (this.form.content.ulb != 255 && this.form.content.ulb !=undefined) {
    //     this.ulbEnu = true;
    //   } else {
    //     this.ulbEnu = false;
    //   }
    // },
    stationChanged: function () {
      this.$axios.post(`api/core/GetPoleList`, {
        "groupId": this.groupTreeSelectedNode.id, "poleId": "", "name": "", "index": 1, "size": 2000000,
      }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'poles', res.data.list);
        }
      });
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if (this.form.area <= 0) {
        this.$Message.warning(this.showLang('select.group'));
        return;
      }
      this.form.list.forEach(el => {
        el.before=el.before*1;
        el.count=el.count*1;
        el.adjust=el.adjust*1;
        el.powerLower=el.powerLower*1;
        el.powerUpper=el.powerUpper*1;
      });
      this.$axios.post(`api/light/SaveLightConfig`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>