<template>
  <div :ref="`t${node.typeTitle}i${node.id}`" class="tree-node">
    <div :class="['node-item', (groupTreeSelectedNode.id == node.id) ? 'node-selected':'']" @click="nodeClick">
      <!-- <div :class="['node-item-flag', (groupTreeSelectedNode.id == node.id) ? 'node-selected-flag':'']"></div> -->
      <div :style="{width: `${(this.curPath.length - 1) * 10}px`, height: '44px', flex: 'none'}"></div>
      <img class="node-img" :src="getImgType()">
      <span :class="[nodeTextClass , (groupTreeSelectedNode.id == node.id) ? 'node-text-class':'']" :title="node.name">{{node.name}}</span>
      <img v-if="node.children && node.children.length > 0" class="expand-img" :src="node.expand ? img.visual.tree.up : img.visual.tree.down">
    </div>
    <div v-if="node.expand" class="tree-nodes">
      <template v-if="node.children">
        <MyTreeNode v-for="(n, idx) in node.children" :path="curPath" :level="idx" :key="`${n.typeTitle}_${n.id}`" :node="n" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MyTreeNode from './MyTreeNode'
export default {
  name: 'MyTreeNode',
  components: {
    MyTreeNode,
  },
  props: {
    node: {
      type: Object,
      default() { return {}; }
    },
    path: {
      type: Array,
      default() { return []; }
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      curPath: [...this.path, this.level],
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode', 'filterTreeNode']),
    nodeTextClass: function () {
      if (this.node.typeTitle == 'root') {
        return 'node-text-root';
      } else if (this.node.typeTitle == 'group') {
        return 'node-text-group';
      } else {
        return 'node-text-dev';
      }
    },
  },
  watch: {
    filterTreeNode() {
      if (this.filterTreeNode.id == this.node.id) {
        this.$refs[`t${this.node.typeTitle}i${this.node.id}`].scrollIntoView({behavior:'auto',block:'center'});
      }
    }
  },
  mounted: function () {
  },
  destroyed: function(){
  },
  methods: {
    getImgType: function () {
      switch (this.node.typeTitle) {
        case 'root': return this.groupTreeSelectedNode.id == this.node.id?this.img.visual.tree.rootActive:this.img.visual.tree.root;
        case 'group': return this.groupTreeSelectedNode.id == this.node.id?this.img.visual.tree.groupActive:this.img.visual.tree.group;
        case 'station': return this.groupTreeSelectedNode.id == this.node.id?this.img.mapPowerOnline:this.img.mapPowerOnline;
        default: return this.img.visual.tree.group;
      }
    },
    nodeClick: function () {
      if (this.groupTreeSelectedNode.id == this.node.id) {
        this.node.expand = !this.node.expand;
      } else {
        this.$store.commit('group/selectGroupTreeNode', this.node);
        if (!this.node.expand) this.node.expand = true;
      }
    },
  }
}
</script>
<style scoped>
.tree-node {
  width: 100%;
  padding: 0 5px;
}
.node-item {
  height: 44px;
  display: flex;
  align-items: center;
  /* background-color: #ffffff; */
  /* border: solid 1px red; */
}
.node-item-flag {
  width: 6px;
  background-color: transparent;
}

.node-text-group {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text-root {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text-dev {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text-class{
  color: #fff;
}
.node-img {
  width: 20px;
  margin: 5px;
  flex: none;
}
.expand-img {
  width: 12px;
  height: 8px;
  margin: 15px;
  flex: none;
}
.node-selected {
  /* background: #ddeffe; */
  background: #3379FB;
  border-radius: 8px;
}
.node-selected-flag {
  background-color: #0cd3fc;
  width: 6px;
  height: 44px;
  background: linear-gradient(0deg, #3176fb, #5eb4ff);
  border-radius: 0px 4px 4px 0px;
}
</style>