<template>
  <div class="page-box">
    <vxe-table border ref='xTable1' :row-config="{isHover: true}" :data="rolesList" :radio-config="{ highlight: true}" :key="indexRefresh">
      <vxe-column field="name" title="用户名"></vxe-column>
      <vxe-column field="remark" title="说明"></vxe-column>
      <vxe-column width="200" fixed="right">
        <template slot="header">
          <Button type="success" @click="rolesEdit()">新增</Button>
          <Button type="primary" @click="GetCommonRoles()" class="ml10">刷新</Button>
        </template>
        <template #default="{ row }">
          <Button type="primary" @click="rolesEdit(row)">修改</Button>
          <Button type="warning" @click="rolesDel(row)" class="ml10">删除</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <roleInfoEdit :item='deliver' v-model="showModal" @saved='GetCommonRoles'></roleInfoEdit>
  </div>
</template>
<script>
import roleInfoEdit from './roleInfoEdit'
export default {
  name: 'GetCommonRoles',

  components: {
    roleInfoEdit
  },
  data() {
    return {
      rolesList: [],
      indexRefresh: 0,
      showModal: false,
      deliver: {
        isAdd: 0,
        data: {},
      }
    }
  },

  mounted: function () {
    this.GetCommonRoles();
    // this.GetModuleList();
  },
  methods: {
    rolesEdit(el) {
      if (el == null) {
        this.deliver.isAdd = 0;
        this.GetRoleOperations({ id: '' });
      } else {
        this.deliver.isAdd = 1;
        this.deliver.data.id = el.id
        this.deliver.data.name = el.name
        this.deliver.data.remark = el.remark
        this.GetRoleOperations({ id: el.id });
      }
    },
    rolesDel(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除角色[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/DeleteCommonRole`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info("删除成功");
              this.GetCommonRoles()
            }
          });
        }
      });
    },
    GetCommonRoles() {
      this.$axios.post(`api/auth/GetCommonRoles`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'rolesList', res.data);
        }
      });
    },

    GetRoleOperations(id) {
      this.$axios.post(`api/auth/GetRoleOperations`, id).then(res => {
        if (res.code == 0) {
          res.data.modules.forEach(el => {
            el.type = 'modules';
            el.isAll = false;
          });
          res.data.functions.forEach(el => {
            el.parentId = el.moduleId;
            el.type = 'functions';
            el.isAll = false;
          });
          res.data.operations.forEach(el => {
            el.parentId = el.functionId;
            el.type = 'operations';
          });
          let arr = [...res.data.modules, ...res.data.functions, ...res.data.operations]
          arr.forEach(el => {
            el.title = el.name;
            el.checkbox = false;
            let targetNode = res.data.checks.find(node => node === el.id);
            if (targetNode != undefined && targetNode.length > 0) {
              el.checkbox = true;
            }
          });
          this.deliver.data.operations = this.buildTree(arr);
          setTimeout(() => {
            this.showModal = true;
          }, 200);
        }
      });
    },
    buildTree(items, parentId = '') {
      return items
        .filter(item => item.parentId === parentId)
        .map(item => ({
          ...item,
          children: this.buildTree(items, item.id),
        }));
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
}
</style>
