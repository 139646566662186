<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">灯杆详细</div>
    <div>
      <Form ref="form" :model="form" :label-width="220">
        <FormItem prop="name" label="名称">
          <Input v-model="form.name" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="name" label="所在分区">
          <Select placeholder="所在分区" v-model="form.groupId" style="width: 200px;">
            <Option v-for="(s, idx) in groupData" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="height" label="灯杆高度">
          <Input v-model="form.height" style="width: 200px"><span slot="append">M</span></Input>
        </FormItem>
        <FormItem prop="buildDate" label="安装时间">
          <DatePicker type="date" v-model="form.buildDate" placeholder="安装时间" style="width: 200px;margin-right: 10px;" />
        </FormItem>
        <FormItem prop="location" label="安装地址">
          <Input v-model="form.location" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="lng" label="经度">
          <Input type="number" v-model="form.lng" placeholder="经度" disabled style="width: 140px; margin: 0 10px;"></Input>
          纬度
          <Input type="number" v-model="form.lat" placeholder="纬度" disabled style="width: 140px; margin: 0 10px;"></Input>
          <Button type="info" style="margin-right: 8px" @click="showMapModal=true">选择位置</Button>
        </FormItem>
      </Form>
      <ModalSelectMapPoint v-model="showMapModal" :custCenter="{ 'area':'','lat':form.lat,'lng': form.lng}" @posSelected="posSelected" />
    </div>
    <div slot="footer">
      <Button type="info" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalSwitchEdit',
  components: {
    ModalSelectMapPoint
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      showMapModal: false,
      groupList: [],
      groupData: [],
      form: {
        "id": "",
        "groupId": "", //所在分区
        "name": '', //名称
        "height": 8, //灯杆高度
        "location": "", //安装地址
        "buildDate": "", //安装时间
        "lat": 0, //纬度
        "lng": 0, //经度
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.dataAjax();
        if (this.item.isAdd) {
          this.form = {
            "id": "",
            "groupId": this.groupTreeSelectedNode.id, //所在分区
            "name": '', //名称
            "height": 8, //灯杆高度
            "location": "", //安装地址
            "buildDate": "", //安装时间
            "lat": this.myMenus.project.lat, //纬度
            "lng": this.myMenus.project.lng, //经度
          }
        } else {
          this.$set(this, 'form', this.item.data);
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    ...mapState('sys', ['myMenus']),
  },
  mounted: function () {
  },
  methods: {
    dataAjax() {
      this.$axios.post(`api/auth/GetMyGroups`, {}).then(res => {
        if (res.code == 0) {
          this.groupList = this.buildTree(res.data)
          this.groupData = [];
          let arr = this.findByNameAndSubsets(this.groupList, this.groupTreeSelectedNode.id);
          this.checkboxIsAll(arr[0])
        }
      });
    },
    buildTree(items, parentId = '') {
      return items.filter(item => item.parentId === parentId).map(item => ({ ...item, children: this.buildTree(items, item.id), }));
    },
    findByNameAndSubsets(groups, id) {
      let result = [];
      function traverse(group) {
        if (group.id === id) {
          result.push({ ...group }); // 使用解构赋值来复制对象，避免直接修改原始数据
        }

        if (group.children && group.children.length > 0) {
          group.children.forEach(child => {
            traverse(child);
          });
        }
      }
      groups.forEach(group => {
        traverse(group);
      });

      return result;
    },
    checkboxIsAll(data) {
      this.groupData.push(data);
      if (data.children.length == 0) return false;
      data.children.forEach(childNode => {
        this.groupData.push(childNode);
        this.setCheckbox(childNode);
      });
    },
    setCheckbox(node) {
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => {
          this.setCheckbox(childNode);
          this.groupData.push(childNode);
        });
      }
    },
    posSelected(params) {
      this.form.location = params.address;
      this.form.lat = params.lat;
      this.form.lng = params.lng;
    },
    ok() {
      this.$axios.post(`api/core/SavePoleInfo`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved");
          this.showModal = false;
        }
      });
    },
  }
}
</script>
<style scoped>
</style>
