<template>
  <div class="page-box">
    <div>
      <Form ref="form" :model="form" :label-width="250">
        <FormItem prop="paramId" label="是否启用微信功能">
          <i-switch v-model="form.enable" size="large" true-color="green" false-color="red">
            <span slot="open">开启</span>
            <span slot="close">禁用</span>
          </i-switch>
        </FormItem>
        <FormItem prop="stationId" label="微信应用ID">
          <Input v-model="form.appId" style="width: 260px"></Input>
        </FormItem>
        <FormItem prop="stationId" label="微信应用密钥">
          <Input v-model="form.appSecret" style="width: 260px"></Input>
        </FormItem>
        <FormItem prop="expired" :label-width="250">
          <Button type="success" @click="saveSetting">保存</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>
<script>

export default {
  name: 'weixin',
  components: {
  },
  data() {
    return {
      form: {
        "enable": false,
        "appId": "wx864d5dd0742b906d",
        "appSecret": "ff63c9af7a058de81ebc249a36421040",
      }
    }
  },
  mounted: function () {
    this.getWeixinSetting();
  },
  methods: {
    getWeixinSetting() {
      this.$axios.post(`api/sys/GetWeixinSetting`, {}).then(res => {
        if (res.code == 0) {
          this.form.enable=res.data.enable;
          this.form.appId=res.data.appId;
          this.form.appSecret=res.data.appSecret;
        }
      });
    },
    saveSetting() {
      this.$axios.post(`api/sys/SetWeixinSetting`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
        }
      });
    }
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding-top: 20px;
}
</style>
