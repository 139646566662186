<template>
  <div class="page-box">
    <div class="list-query">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 110px; margin-right: 5px;"></Input>
      {{showLang('com.group')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.lightGroupId" style="width: 110px;margin-right: 5px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <Option v-for="(s, idx) in areas" :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
      </Select>
      {{showLang('com.export.cn.connid')}}：
      <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')" style="width: 110px; margin-right: 5px;"></Input>
      应用类型:
      <Select :placeholder="showLang('save.select')" v-model="filter.useType" style="width: 110px;margin-right: 5px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <Option v-for="el,index in useTypes" :value="el.id" :key="'useTypes'+index">{{el.name}}</Option>
      </Select>
      设备类型:
      <Select :placeholder="showLang('save.select')" v-model="filter.smallType" style="width: 110px;margin-right: 15px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <Option v-for="el,index in devicesTypes" :value="el.code" :key="'devicesTypes'+index">{{el.name}}</Option>
      </Select>
      <Button type="primary" @click="getList(filter.index=1)">查询</Button>
    </div>
    <div class="alarm-data-query">
      <template v-for="(cmd, idx) in insList">
        <Button v-if="idx < cmdcnt" :key="idx" type="primary" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
      </template>
      <!-- <Dropdown style="margin-right: 10px" v-if="lightCmds.length > cmdcnt">
        <Button type="primary">
          {{showLang('com.op.other')}}<Icon type="ios-arrow-down"></Icon>
        </Button>
        <div slot="list" class="btns-flex" style="width: 650px">
          <template v-for="(item, idx) in insList">
            <div :key="idx" class="layoutDataBox">
              <p class="layoutData-title"><i class="layoutData-drop"></i>{{item}}</p>
              <div class="layout-Box">
                <span v-for="(cmd,ind) in moreCmds" :key="ind">
                  <Button v-if="cmd.group == item" :key="`${cmd.code}`+ind" type="primary" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
                </span>
              </div>
            </div>
          </template>
        </div>
      </Dropdown> -->
      <!-- <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="exportResult">{{showLang('com.op.export.results')}}</Button>
      <Checkbox v-model="clearOldLog">{{showLang('com.lamp.is.ins')}}</Checkbox> -->
    </div>
    <div class="table1-data" ref='table1'>
      <!--  public bool Online { get; set; }
            public bool Alarm { get; set; }
            public bool Running { get; set; } -->
      <!-- <vxe-table border ref='chkTable' header-align="center" align="center" :height="tabHeight" :row-config="{isHover: true}" :data="list"  :checkbox-config="{checkField: 'checked',}" class="mt20"   @checkbox-all='selectChangeEventAll'> -->
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" @checkbox-change="selectChangeEvent" :data="list" :height="tabHeight" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}" @checkbox-all='selectChangeEventAll'>
        <vxe-column type="seq" width="60" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" title="名称" width="170" fixed="left"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="170" fixed="left"></vxe-column>
        <vxe-column field="poleName" title="所在灯杆" width="170"></vxe-column>
        <vxe-column field="lightGroupName" title="灯控分组" width="170"></vxe-column>
        <vxe-column field="code" title="通信ID" width="170"></vxe-column>
        <vxe-column field="voltage" title="电压/V" width="100">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-for='el,index in row.channels' :class="['split-row-item',  row.channels.length>(index+1)? 'split-row-flag' : '']" :key="index">{{ el.voltage }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="current" title="电流/A" width="100">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-for='el,index in row.channels' :class="['split-row-item',  row.channels.length>(index+1)? 'split-row-flag' : '']" :key="index">{{ el.current }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="power" title="功率/W" width="100">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-for='el,index in row.channels' :class="['split-row-item',  row.channels.length>(index+1)? 'split-row-flag' : '']" :key="index">{{ el.power }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="output" title="亮度/%" width="100">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-for='el,index in row.channels' :class="['split-row-item',  row.channels.length>(index+1)? 'split-row-flag' : '']" :key="index">{{ el.output }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="quality" title="电能/KWh" width="100">
          <template #default="{ row }">
            <div class="split-row-area">
              <div v-for='el,index in row.channels' :class="['split-row-item',  row.channels.length>(index+1)? 'split-row-flag' : '']" :key="index">{{ el.quality }}</div>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="heartTime" title="最后通信时间" width="220"></vxe-column>
        <vxe-column field="result" :title="showLang('com.ins.result')" fixed="right" width="300" sortable :show-overflow-tooltip="true">
          <template #default="{ row }">
            {{row._message}}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :current-page="filter.index" :page-size="filter.size" :total="count" :page-sizes="[20, 100, 500, 1000, 5000]" :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="handlePageChange">
    </vxe-pager>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" :areas="areas" @saved="argsSaved" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'stationMain',
  components: {
    ModalArgEdit
  },
  props: {

  },
  data() {
    return {
      list: [],
      count: 0,
      cmdcnt: 8,
      tabHeight: 500,
      areas: [],
      useTypes: [],
      devicesTypes: [],
      filter: {
        "groupId": "", //所在分区ID
        "name": "", //灯控名称
        "code": "", //灯控通信ID
        "useType": 0, //应用类型
        "smallType": 0, //设备类型
        "lightGroupId": "", //灯控分组ID
        "index": 1, //当前页码
        "size": 20, //分页大小
      },
      insList: [],
      cmdResults: [],
      cmdItem: { item: {}, other: {}, args: {} },
      showArgModal: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang',]),
    ...mapState('group', ['groupTreeSelectedNode']),
    ...mapState('ins', ['lightIns']),
    ...mapState('sys', ['myMenus']),
  },
  watch: {
    'groupTreeSelectedNode.id'() {
      this.getList();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.getInsList()
    this.getList();
    this.searchAjax();
    setTimeout(this.setTableHeight, 200);
    window.eventBus.$on('paramCommandComing', params => {
      debugger;
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    });
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    searchAjax() {
      this.$axios.post(`api/light/GetLightGroupList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'areas', res.data);
        }
      });
      this.$axios.post(`api/light/GetUseTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'useTypes', res.data);
        }
      });
      this.$axios.post(`api/light/GetDevicesTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devicesTypes', res.data);
        }
      });
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage;
      this.filter.size = pageSize;
      this.getList(true);
    },
    setTableHeight() {
      this.tabHeight = this.$refs.table1.clientHeight - 20;
    },
    selectChangeEventAll(checked) {
      let arr = [];
      checked.records.forEach((el) => {
        arr = this.list.filter(p => p.id == el.id);
        arr.forEach((item, index) => {
          if (index != 0) {
            item.checked = false;
          }
        });
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.list.forEach((el, index) => {
        if (el.id == this.list[rowIndex].id && index != rowIndex) {
          el.checked = false
        }
      });
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    getList() {
      this.filter.groupId = this.groupTreeSelectedNode.id == 0 ? '' : this.groupTreeSelectedNode.id;
      this.$axios.post(`api/light/GetLightStateList`, this.filter).then(res => {
        if (res.code == 0) {
          res.data.list.forEach(el => {
            el._message = '';
          });
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'count', res.data.count);
        }
      });
    },
    getInsList() {
      this.insList = [];
      this.$axios.post(`api/auth/GetFunctionOpreations`, { code: 'b103' }).then(res => {
        if (res.code == 0) {
          res.data.my.forEach(el => {
            let foundItem = this.lightIns.find(item => item.code === el);
            if (foundItem != undefined) {
              this.insList.push(foundItem)
            }
          });

        }
      });
    },
    clickCmd(params) {
      console.log(params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.lamp.select'));
        return;
      }
      if (!params.needPswd) {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
      } else {
        for (let item of this.list) {
          item._message = '';
          item._status = 0;
        }
        let cmds = []
        for (let chk of chks) {
          chk._message = '';
          chk._status = 0;
          if (!cmds.includes(chk.id)) cmds.push(chk.id);
        }
        let cmd = {
          command: params.code,
          list: cmds,
          content: {}
        }
        this.sendCommand(cmd);
      }
    },
    argsSaved(params) {
      console.log(params)
      for (let item of this.list) {
        item._message = '';
        item._status = 0;
      }
      let cmds = []
      let chks = this.$refs.chkTable.getCheckboxRecords();
      for (let chk of chks) {
        chk._message = '';
        chk._status = 0;
        if (!cmds.includes(chk.id)) cmds.push(chk.id);
      }
      let cmd = {
        command: params.code,
        list: cmds,
        content: {}
      }
      this.sendCommand(cmd);
    },
    sendCommand: function (cmd) {
      if (cmd.list.length == 0) return;
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`api/light/SendCommand`, cmd).then(res => {
        if (res.code == 0) {
          for (let item of res.data.list) {
            let ds = this.list.filter(p => p.id == item.id);
            if (ds.length == 0) continue;
            this.$set(ds[0], '_cmdCode', cmd.code);
            this.$set(ds[0], '_cmdId', item.success ? item.message : '');
            this.$set(ds[0], '_status', item.success ? 1 : 8);
            this.$set(ds[0], '_content', {});
            this.$set(ds[0], '_message', item.success ? '指令已进入发送队列' : item.message);
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.table1-data {
  height: 100px;
  flex: auto;
}
.split-row-area {
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item {
  flex: none;
  text-align: center;
}
.split-row-flag {
  border-bottom: solid 1px #dcdee2;
}
</style>
