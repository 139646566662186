<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">角色信息</div>
    <div>
      <Form ref="form" :model="form" :label-width="100">
        <FormItem prop="name" label="角色名称">
          <Input v-model="form.name" style="width: 270px"></Input>
        </FormItem>
        <FormItem prop="remark" label="角色说明">
          <Input v-model="form.remark" style="width: 270px"></Input>
        </FormItem>
      </Form>
      <p>
        <Badge status="success" class="ml20" />模块权限
        <Badge status="processing" class="ml20" />功能权限
        <Badge status="warning" class="ml20" /> 操作权限
        <Checkbox v-model="checkboxAll" class="ml20">全选</Checkbox>
      </p>
      <div class="role-operations">
        <operationsBox :item='form.operations'></operationsBox>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import operationsBox from './operationsBox'
export default {
  name: 'roleInfoEdit',
  components: {
    operationsBox
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      checkboxAll: false,
      operations: [],
      form: {
        "id": "",
        "name": "",
        "remark": "",
        "operations": [], //勾选的操作列表
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.item.isAdd == 0 && this.showModal) {
        this.form = {
          "id": "",
          "name": "",
          "remark": "",
          "operations": this.item.data.operations,
        }
      }
      if (this.item.isAdd == 1 && this.showModal) {
        this.form = {
          "id": this.item.data.id,
          "name": this.item.data.name,
          "remark": this.item.data.remark,
          "operations": this.item.data.operations,
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    checkboxAll() {
      this.form.operations.forEach(node => {
        this.setCheckbox(node, this.checkboxAll)
      });
    },
  },
  mounted: function () {
  },
  methods: {
    setCheckbox(node, isAll) {
      node.checkbox = isAll;
      node.isAll = isAll;
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => {
          this.setCheckbox(childNode, isAll);
        });
      }
    },
    getCheckbox(node) {
      if (node.checkbox) {
        if(node.type=='operations'){
          this.operations.push(node.id)
        }
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => {
          this.getCheckbox(childNode);
        });
      }
    },
    ok() {
      this.operations = [];
      this.form.operations.forEach(node => {
        this.getCheckbox(node)
      });
      let ajaxData = {
        "id": this.form.id,
        "name": this.form.name,
        "remark": this.form.remark,
        "operations": this.operations,
      }
      this.$axios.post(`api/auth/SaveCommonRole`, ajaxData).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved")
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}
.role-operations {
  height: 500px;
  overflow-y: auto;
}
</style>
