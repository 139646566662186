<template>
  <div class="auth-container">
    <div class="auth-header">
      <template v-for="item in menus">
        <div v-if="item.show" :key="item.code" :class="['auth-menu', currentName == item.code ? 'menu-selected' : '']" @click="menuSelected(item.code)">{{item.name}}</div>
      </template>
    </div>
    <div class="auth-content">
      <template v-for="(item, idx) in menus">
        <div :key="'tree'+idx" v-if="item.tree && item.code == currentName && item.show" class="treeGroup">
          <treeGroup></treeGroup>
        </div>
        <component v-if="item.code == currentName && item.show" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import lightGroupList from './lightGroupList/index'
import plan1 from './plan1/Index'
import plan2 from './plan2/Index'
import treeGroup from '@/pages/treeGroup/Index'
import lamp from './lamp/Index'
import poleList from './poleList/index'
export default {
  name: 'FactorySystemIndex',
  components: {
    treeGroup
  },
  data() {
    return {
      isCollapsed: false,
      currentName: 'p001',
      menus: [
        { code: 'p001', name: '灯控管理', com: lamp, show: true, args: {}, tree: true },
        { code: 'p005', name: '灯杆管理', com: poleList, show: true, args: {}, tree: true },
        { code: 'p002', name: '灯控分组', com: lightGroupList, show: true, args: {}, tree: false },
        { code: 'p003', name: '计时日表', com: plan1, show: true, args: {}, tree: false },
        { code: 'p004', name: '准时日表', com: plan2, show: true, args: {}, tree: false },
      ],
      count: 0,
    }
  },
  computed: {
    ...mapState('sys', ['myMenus', 'menusId']),
  },
  mounted: function () {
    // this.menusList()
  },
  methods: {
    menuSelected: function (name) {
      if (this.currentName == name) return;
      this.currentName = name;
    },
  }
}
</script>
<style scoped>
.auth-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.auth-header {
  flex: none;
  display: flex;
  align-items: flex-end;
}
.auth-menu {
  height: 42px;
  padding: 0 24px;
  margin: 0 15px 0 0;
  cursor: pointer;
  white-space: nowrap;
  color: #525c76;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 18px;

  line-height: 42px;
  border-radius: 8px 8px 0px 0px;
}
.treeGroup {
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.auth-content {
  height: 400px;
  flex: auto;
  padding: 3px;
  background: #f5f6fa;
  border-radius: 0 10px 0 0;
  display: flex;
}
.menu-selected {
  color: #3f75ff;
  background: #f5f6fa;
}
</style>
