<template>
  <div>
    <template v-for="el in item">
      <div :key="'modules'+el.id" v-if="el.type=='modules'" class="mt15">
        <Badge status="success" />
        <Checkbox v-model="el.checkbox">{{el.name}}</Checkbox>
        <Checkbox v-model="el.isAll" class="ml20" @on-change="checkboxIsAll(el.id,$event)">全选</Checkbox>
        <div v-if="el.children && el.children.length > 0" class="ml10">
          <OperationsBox v-for="child in el.children" :key="child.id" :item="[child]"></OperationsBox>
        </div>
      </div>
      <div :key="'functions'+el.id" v-if="el.type=='functions'" class="ml10 mt10">
        <Badge status="processing" />
        <Checkbox v-model="el.checkbox">{{el.name}}</Checkbox>
        <Checkbox v-model="el.isAll" class="ml20" @on-change="checkboxIsAll(el.id,$event)">全选</Checkbox>
        <div class="operationsBox ml10 mt10">
          <template v-for="ele in el.children">
            <div :key="'operations'+ele.id" class="mr10">
              <Badge status="warning" />
              <Checkbox v-model="ele.checkbox">{{ele.name}}</Checkbox>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import OperationsBox from './operationsBox.vue'
export default {
  name: 'OperationsBox',
  components: {
    OperationsBox
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  mounted: function () {

  },
  methods: {
    checkboxIsAll(id, event) {
      let targetNode = this.item.find(node => node.id === id);
      if (targetNode) {
        targetNode.checkbox = event;
        targetNode.isAll = event;
        targetNode.children.forEach(childNode => {
          this.setCheckbox(childNode, event, id)
        });
      }
    },
    setCheckbox(node, isAll, id) {
      node.checkbox = isAll;
      if (node.isAll != undefined) {
        node.isAll = isAll;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => {
          this.setCheckbox(childNode, isAll, id);
        });
      }
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}

.operationsBox {
  display: flex;
  flex-wrap: wrap;
}
</style>
