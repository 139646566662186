<template>
  <div class="station-box">
    <div class="treeGroup">
      <treeGroup />
    </div>
    <div class="station-main">
      <mainPage />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import treeGroup from '@/pages/treeGroup/Index'
import mainPage from './main'
export default {
  name: 'station',
  components: {
    treeGroup,
    mainPage,
  },
  props: {

  },
  data() {
    return {
      isShow: 'group',//group station
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  watch: {

  },
  mounted: function () {
  },
  methods: {

  }
}
</script>
<style scoped>
.station-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
}
.treeGroup {
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.station-main{
  width: calc(100% - 320px);
}
</style>
