<template>
  <div class="tree-container">
    <MyTreeNode v-for="(node, idx) in data" :path="[]" :level="idx" :key="`_${node.id}`" :node="node" />
  </div>
</template>
<script>
import MyTreeNode from './MyTreeNode'
  export default {
    name: 'MyTree',
    components:{
      MyTreeNode,
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      data: {
        type: Array,
        default(){ return [];}
      },
    },
    data () {
      return {
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    
    }
  }
</script>
<style scoped>
.tree-container{
  width: 100%;
  /* background-color: #041B32; */
  /* background-color: #ffffff; */
  padding-bottom: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
  .context-menu{
    display: none;
    position: absolute;
    width: 200px;
    height: 200px;
    background: oldlace;
  }
</style>